<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
        {{ $t('forms.add_model', {model: $t('models.licence')})}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmitLicence)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="Full Name"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.fullName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Username"
            rules="required"
          >
            <b-form-group
              label="Email / Username"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="userData.username"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required|password"
          >
            <b-form-group
              label="Password"
              label-for="password"
            >
              <b-form-input
                id="password"
                type="password"
                v-model="userData.password"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Max Children -->
          <validation-provider
            #default="validationContext"
            name="Max Children"
            rules="required|integer"
          >
            <b-form-group
              label="Max Children"
              label-for="max_children"
            >
              <b-form-input
                id="max_children"
                type="number"
                min="0"
                v-model="userData.max_children"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Language -->
          <validation-provider
            #default="validationContext"
            name="Language"
            rules="required"
          >
            <b-form-group
              label="Language"
              label-for="lang"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.lang"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="langsOptions"
                :reduce="val => val.label"
                :clearable="false"
                input-id="lang"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="User Role"
            rules="required"
          >
            <b-form-group
              label="User Role"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- if role != Partner, mostrar payment_method -->
          <template v-if="userData.role && userData.role != 3">
            <!-- Plan -->
            <validation-provider
              #default="validationContext"
              name="Plan"
              rules="required"
            >
              <b-form-group
                label="Plan"
                label-for="plan"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.currentPlan"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="planOptions"
                  :selectable="val => val.can_select"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="plan"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- SUBSCRIPTION -->
            <b-form-group>
              <b-form-checkbox
                v-model="userData.subsSelected"
                plain
              >
                Free Subscription
              </b-form-checkbox>
            </b-form-group>

            <!-- SUBSCRIPTION DATES -->
            <template v-if="!userData.subsSelected">
            <!-- VALID_FROM -->
            <validation-provider
              #default="validationContext"
              name="valid_from"
            >
            <b-form-group
                label="Valid From"
                label-for="valid_from"
                :state="getValidationState(validationContext)"
              >
              <b-form-datepicker
                id="valid_from"
                v-model="userData.valid_from"
                class="mb-1"
              />
            </b-form-group>
            </validation-provider>

            <!-- VALID_UNTIL -->
            <validation-provider
              #default="validationContext"
              name="valid_until"
            >
            <b-form-group
                label="Valid Until"
                label-for="valid_until"
                :state="getValidationState(validationContext)"
              >
              <b-form-datepicker
                id="valid_until"
                v-model="userData.valid_until"
                class="mb-1"
                :min="minDate"
              />
            </b-form-group>
            </validation-provider>
            </template>

          </template>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('forms.add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('forms.cancel')}}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormDatepicker, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormDatepicker, 
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    langsOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    const now = new Date()
    const tomorrow = new Date(now)
    tomorrow.setDate(tomorrow.getDate() + 1)


    return {
      required,
      alphaNum,
      password,

      minDate: tomorrow,

    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      password: '',
      role: 2,
      currentPlan: null,
      lang: 'Spanish',
      max_children: 5,
      subsSelected: true,
      valid_from: '',
      valid_until:''
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods:{
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    validate(){
      if(this.userData.valid_from > this.userData.valid_until){
        this.makeToast('danger', this.$t('Error'), 'wrong date');
        return false
      }
      return true
    },
    async onSubmitLicence(){
      this.userData['context'] = 'licence';
      console.log(this.userData)
      if(this.validate()){
        await this.$http.post('createLicence', this.userData).then( response => {
          console.log(response)
          if(response.data.status == 200){
            this.makeToast('success', this.$t('Success'), this.$t('forms.save_success'));
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
          } else {
            this.makeToast('danger', this.$t('Error'), response.data.msg);
          }
        }).catch(error => {
          console.log("Err -> ", error);
        })
      }



    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
