<template>
  <div>
    <custom-breadcrumb
      :pageTitle="$t('Licences')"
      :breadcrumb="[{
              text: $t('Licences'),
              active: true,
            }]"
    ></custom-breadcrumb>

      <licence-list-add-new
        :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
        :role-options="roleOptions"
        :plan-options="planOptions"
        :langsOptions="langsOptions"
        @refetch-data="getData"
      />

 <import-wizard
      v-if="showImportModal"
      @close="showImportModal = false"
    ></import-wizard>
    
<b-card no-body>
    
    <!-- FILTROS -->
    <filters
      ref="filter"
      context="licences"
      :role-options="roleOptions"
      :status-options="statusOptions"
      @set-data="setData"
      @set-loading-data="setloadingData"
    /> 
    
    <!-- TABLA -->
    <paginated-table
      :data="users"
      ability="Licences"
      link='licences'
      :canDownload="true"
      :fetch_message="fetch_message"
      @manage-selected-rows="manageSelectedRows"
      @delete="deleteModel"
      @recover="recoverModel"
      :loading_data="loading_data"

      :buttonsOptions="!showImportModal ? buttonsOptions: importOptions"
      @close="showImportModal = false"
      @create="isAddNewUserSidebarActive = true"
      @import="showImportModal = true"
      @deleteSelection="deleteSelection" 

    ></paginated-table>
        
    </b-card>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import {
  BCard, BCardBody, BRow, BCol, BFormGroup,  BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BFormCheckbox, BFormSelect, BCardHeader, BCardTitle, BBadge, BDropdown, BDropdownItem, BPagination, BSkeletonTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Filters from '@/views/sl-components/Filters.vue'
import LicenceListAddNew from './AddNew.vue'
import PaginatedTable from '@/views/sl-components/PaginatedTable.vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import ImportWizard from '@/views/sl-components/ImportWizard.vue'

export default {
    components: {
      PaginatedTable,
      CustomBreadcrumb,
      ImportWizard,

        BButton,
        BAvatar,
        BBadge,
        BCardBody,
        BCardHeader,
        BCardTitle, 
        BCard,
        BCol,
        BDropdown,
        BDropdownItem,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BLink,
        BMedia,
        BPagination,
        BRow,
        BSkeletonTable,
        BTable,

        vSelect,

        Filters,
        LicenceListAddNew,
    },
    setup(){
        return {
            avatarText
        }
    },
    data(){
        return {
          
          roleOptions: [],
          planOptions: [],
          langsOptions: [],
          statusOptions: [],
          users: [],
          users_length: null, 
          loading_data: false,
          fetch_message: '',
          
          isAddNewUserSidebarActive: false,
          selected: [],
          showImportModal: false,
          importOptions: [
            {
              action: "close",
              icon: "XIcon",
              name: "Go Back to List"
            },
          ],
        }
    },
    computed: {
      buttonsOptions(){
        let opt = [
          {
            action: "create",
            variant: "success",
            icon: "PlusIcon",
            name: this.$t('forms.add_model', {model: this.$t('models.licence')})
          },
          {
            action: "import",
            icon: "UploadIcon",
            name: this.$t('import_wizard.import_model', {model: this.$t('Licences')})
          },
        ]
        if(this.selected.length > 0){
          opt.push({
                action: "deleteSelection",
                icon: "Trash2Icon",
                name: this.$t('table.deactivate_selection', {model: this.$t('models.records')}),
                variant: "danger"
              })
        }
        return opt
      },
    },
    methods: {
      manageSelectedRows(selected_rows){
        console.log(selected_rows, "selected_rows")
        this.selected = selected_rows
      },
      async getData(){
        console.log("loading")
        await this.$http.post('licences', {}).then( response =>{
          console.log(response)
          let roles = response.data.roles;
          let plans = response.data.plans;
          let langs = response.data.langs;
          //this.users = response.data.users;

          

          //ROLES
          let newRolesOptions = []
          roles.forEach(rol => {
            if(rol.name != 'partner'){
              let capName = rol.name.charAt(0).toUpperCase() + rol.name.slice(1);
              newRolesOptions.push(
                  { 'value': rol.id, 'label': capName }
              ) 
            }
          });
          this.roleOptions = newRolesOptions;

          //PLANS
          let newPlansOptions = []

          plans.forEach(x => {
            let can_select = true;
            if(x.canEdit==0){
              can_select = false
            }
            newPlansOptions.push(
                { 'value': x.id, 'label': x.description, 'can_select': can_select }
            ) 
          });
          this.planOptions = newPlansOptions;

          //LANGS
          let newLangsOptions = []
          langs.forEach(x => {
          let capName = x.name.charAt(0).toUpperCase() + x.name.slice(1);
          newLangsOptions.push(
              { 'value': x.id, 'label': capName }
          ) 
          });
          this.langsOptions = newLangsOptions;

          //STATUS
          this.statusOptions = [
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
          ]

          //this.setData(response);
        }).catch(error => {
          console.log("Err -> ", error)
      })
      },
      setloadingData(state){
          this.loading_data = state
      },
      setData(data){
          if(data.users_length > 40000){
              this.users = []
              this.fetch_message = 'The data cannot be displayed because the query returned too many records. Please try filtering your data.'
              this.loading_data = false
              this.users_length = 0
          } else {
              this.fetch_message = ''
              this.users = data.users;
              this.loading_data = false
              this.users_length = data.users_length
          }
      },
      recoverModel(id){
        this.$bvModal
          .msgBoxConfirm('Are you sure you want to recover this licence?', {
            title: 'Recover licence',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let data = {
                'user_id': id
              }
              this.$http.post('recoverLicence', data).then( response =>{
                console.log(response)
                if(response.data.status == 200){
                  //this.$refs.filter.search();
                  let prev_users = this.users
                  prev_users.map(x => {
                    if(x.id == id){
                      let email = x.email.replace('#deleted__', '');
                      email.replace('__deleted#', '');
                      x.email = email
                      x.deleted_at = null
                    }
                  })
                  this.users = prev_users
                  this.makeToast('success', this.$t('Success'), this.$t('forms.recover_success'));

                } else {
                  console.log(response.data, "NO SE PUEDE RECUPERAR");
                  this.makeToast('danger', this.$t('Error'), this.$t('forms.recover_error'));
                }

              }).catch(error => {
                console.log("Err -> ", error)
              })
            }
          })
      },
      deleteModel(id){
        this.$bvModal
          .msgBoxConfirm(this.$t('table.delete_one_confirmation'), {
            title: this.$t('forms.delete'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let data = {
                'user_id': id
              }
              this.$http.post('deleteLicence', data).then( response =>{
                console.log(response)
                if(response.data.status == 200){
                  //this.$refs.filter.search();
                  let prev_users = this.users
                  prev_users.map(x => {
                    if(x.id == id){
                      x.email = '#deleted__'+x.email+'__deleted#'
                      x.deleted_at = new Date()
                    }
                  })
                  this.users = prev_users
                  this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));

              } else {
                this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }

              }).catch(error => {
                console.log("Err -> ", error)
              })
            }
          })
      },
      deleteSelection(){
        this.$bvModal
          .msgBoxConfirm(this.$t('table.delete_selection_confirmation', {num:this.selected.length}), {
            title: this.$t('table.delete_selection'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value){
              let sel = this.selected.map(item => item.id)
              let data = {
                'records_ids':   sel
              }
              this.$http.post('deleteLicenceMasive', data).then( response =>{
                if(response.data.status == 200){
                  this.$refs.filter.search();

                  this.makeToast('success', this.$t('Success'), this.$t('forms.delete_all.success'));

                } else {
                  this.makeToast('danger', this.$t('Error'), this.$t('forms.delete_all.error'));
                }

              }).catch(error => {
                console.log("Err -> ", error)
              })
            }
          })
      
      },
      makeToast(variant = null, tit ='',msg = '') {
        this.$bvToast.toast(msg, {
          title: tit,
          variant,
          solid: false,
        })
      },
    },
    mounted(){
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>